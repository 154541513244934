// shared theme local variables
@import 'variables';
@import "@atc/axl-demo/scss/axl-demo";

/* FYC-only components */
@import "components/filters";
@import "components/alpha_showcase";
@import "components/media_gallery_image";
@import "components/media_gallery_modal";
@import "components/page_overlay";
@import "components/performance-accessibility";
@import "components/sticky_header";

// *** CSS Overrides ***
// TODO: Temp for Sticky Header until specs are released
.sticky-container>header {
    background: linear-gradient($white, $blue-100) !important;

    div {
        color: $gray-800;
    }
}
